import { useState, useEffect, useRef } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../../context/FirebaseConfig';

/**
 * Custom hook to manage velocity data from Firestore
 * Handles subscribing to velocity updates and calculating averages
 */
export function useVelocityData(currentUser) {
    const [velocities, setVelocities] = useState({});
    const velocityBufferRef = useRef({});
    const velocityUpdateInterval = 5;

    // Update the average velocity for each device
    // Velocity is computed as the average of the last x updates (x = velocityUpdateInterval)
    useEffect(() => {
        if (!currentUser?.devices || !currentUser?.company?.company) {
            return;
        }

        const volumeUnsubscribes = {};
        const VOLUME_DOC_ID = "volume_tracking";
        
        for (const deviceId of Object.keys(currentUser.devices)) {
            // Initialize the buffer for each device to store the last x values (x = velocityUpdateInterval)
            if (!velocityBufferRef.current[deviceId]) {
                velocityBufferRef.current[deviceId] = {values: [], initialDisplayed: false};
            }

            const volumesDocRef = doc(
                firestore, 
                "clients", 
                currentUser.company.company, 
                "devices", 
                deviceId, 
                "volumes", 
                VOLUME_DOC_ID
            );

            const unsubscribe = onSnapshot(volumesDocRef, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.data();
                    const newValue = data.current_belt_velocity;
                    const unit = data.belt_velocity_units;
                    const lastAlive = data.last_alive.toDate();
                    const lastUpdate = data.last_update.toDate();

                    // if device is not alive, set avg to undefined
                    if (lastAlive && (new Date() - lastAlive) > 60000) {
                        setVelocities((prev) => ({
                            ...prev,
                            [deviceId]: {avg: undefined, unit: unit, lastAlive: lastAlive}
                        }));
                        return;
                    }
                    
                    // Use the buffer object from the ref.
                    const bufferObj = velocityBufferRef.current[deviceId];

                    if (lastUpdate && (new Date() - lastUpdate) > 60000) {
                        setVelocities((prev) => ({
                            ...prev,
                            [deviceId]: {avg: 0, unit: unit, lastAlive: lastAlive}
                        }));
                        bufferObj.values = [];
                        bufferObj.initialDisplayed = false;
                    } else {
                        /// If no value is shown yet for this device, update immediately.
                        if (!bufferObj.initialDisplayed) {
                            setVelocities((prev) => ({
                                ...prev,
                                [deviceId]: {avg: newValue, unit: unit, lastAlive: lastAlive}
                            }));
                            bufferObj.values.push(newValue);
                            bufferObj.initialDisplayed = true;
                        } else {
                            // For subsequent updates, only add to the buffer
                            bufferObj.values.push(newValue);
                        }
                        // When the buffer reaches the desired length, compute the average.
                        if (bufferObj.values.length === velocityUpdateInterval) {
                            const sum = bufferObj.values.reduce((a, b) => a + b, 0);
                            const avg = sum / velocityUpdateInterval;
                            setVelocities((prev) => ({
                                ...prev,
                                [deviceId]: {avg: avg, unit: unit, lastAlive: lastAlive}
                            }));
                            // Clear the buffer for the next set of updates.
                            bufferObj.values = [];
                        }
                    }
                }
            });
            
            volumeUnsubscribes[deviceId] = unsubscribe;
        }
        
        // Check periodically for stale velocity data
        const checkStaleData = () => {
            setVelocities((prev) => {
                const updated = {...prev};
                Object.entries(updated).forEach(([deviceId, data]) => {
                    if (!data.lastAlive) return;
                    const isStale = Date.now() - data.lastAlive.getTime() > 60000;
                    if (isStale) {
                        updated[deviceId] = {...data, avg: undefined};
                    }
                });
                return updated;
            });
        };
        
        const interval = setInterval(checkStaleData, 20000);
        
        return () => {
            Object.values(volumeUnsubscribes).forEach((unsubscribe) => unsubscribe());
            clearInterval(interval);
        };
    }, [currentUser]);

    return { velocities };
} 