import React from 'react';
import { Link } from 'react-router-dom';
import edit_icon from '../../../assets/icons/edit.png';
import edit_icon_dark from '../../../assets/icons/edit-black.png';
import clipboard_light from '../../../assets/icons/clipboard_light.png';
import clipboard_dark from '../../../assets/icons/clipboard_dark.png';
import speed_icon_white from "../../../assets/icons/speed_white.png";
import menu_icon_white from "../../../assets/icons/menu_darkmode.png";
import menu_icon_dark from "../../../assets/icons/menu_lightmode.png";
import back_icon from '../../../assets/icons/back_arrow_white.png';
import back_icon_dark from '../../../assets/icons/back_arrow_black.png';
import row_add_icon from '../../../assets/icons/row_add.png';
import row_add_icon_black from '../../../assets/icons/row_add_black.png';
import row_remove_icon from '../../../assets/icons/row_remove.png';
import row_remove_icon_black from '../../../assets/icons/row_remove_black.png';
import col_add_icon from '../../../assets/icons/column_add.png';
import col_add_icon_black from '../../../assets/icons/column_add_black.png';
import col_remove_icon from '../../../assets/icons/column_remove.png';
import col_remove_icon_black from '../../../assets/icons/column_remove_black.png';
import accept_icon from '../../../assets/icons/checkmark.png';

/**
 * TopNavigation component for the FeedTimestamping page
 * Displays conveyor controls and action buttons
 */
const TopNavigation = ({ 
    currentUser, 
    deviceName, 
    velocities, 
    editMode, 
    toggleEdit, 
    uiMode, 
    setShowSideMenu,
    switchDevice,
    shiftInfo,
    settings,
    conveyorCode,
    linkedDevices,
    appSettings,
    // New props for edit controls
    addRow,
    removeRow,
    addColumn,
    removeColumn,
    nRows,
    nColumns
}) => {
    // Get the active user's name from settings based on shiftInfo.user
    const getActiveUserName = () => {
        if (shiftInfo?.user && settings?.users && settings.users[shiftInfo.user]) {
            const user = settings.users[shiftInfo.user];
            return `${user.firstname} ${user.lastname}`;
        }
        return currentUser?.displayName || currentUser?.email || "User";
    };

    return (
        <div className={`top-navigation ${editMode ? 'edit-mode' : ''}`}>
            {/* Show these elements only when NOT in edit mode */}
            {!editMode && (
                <>
                    <div className="conveyor-controls">
                        {/* Conditionally render conveyor selection dropdown based on settings */}
                        {appSettings?.navbar_display?.conveyor_selection_dropdown !== false && (
                            <div className="conveyor-label">
                                <div className="conveyor-selector">
                                    <select
                                        className="conveyor-select"
                                        value={linkedDevices?.length > 0 ? "all" : currentUser.device_id}
                                        onChange={(e) => {switchDevice(e.target.value);}}
                                    >
                                        {Object.entries(currentUser?.devices ?? {}).map(([key, value]) => (
                                            <option key={key} value={key}>
                                                {value?.display_name ?? key}
                                            </option>
                                        ))}
                                        {Object.keys(currentUser?.devices || {}).length > 1 && (
                                            <option value="all">Alle Geräte</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        )}
                        
                        {/* Conditionally render speed indicator based on settings */}
                        {appSettings?.navbar_display?.conveyor_speed_indicator !== false && (
                            <div className={`speed-indicator ${
                                velocities[currentUser?.device_id]?.avg === undefined ? 'speed-undefined' : 
                                velocities[currentUser?.device_id]?.avg > 0 ? 'speed-moving' : 'speed-stopped'
                                }`}>
                                <div className="speed-display-icon">
                                    <img src={speed_icon_white} alt="Speed" />
                                    <span className="conveyor-name">
                                        {conveyorCode}
                                    </span>
                                </div>
                                <div className="speed-display-value">
                                    <div className="speed-value-container">
                                        <span className="speed-value">
                                            {velocities[currentUser?.device_id]?.avg ? 
                                                Math.round(velocities[currentUser?.device_id]?.avg * 100) / 100 : 
                                                "0.00"}
                                        </span>
                                        <span className="speed-unit">
                                            {velocities[currentUser?.device_id]?.unit || "m/min"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    
                    <div className="action-buttons">
                        <button className="edit-companies-button" onClick={toggleEdit}>
                            <img src={uiMode === "dark-mode" ? edit_icon : edit_icon_dark} alt="Edit Fields" />
                            <span>Felder bearbeiten</span>
                        </button>
                        
                        <Link className="history-button" to="/corrections">
                            <img src={uiMode === "dark-mode" ? clipboard_light : clipboard_dark} alt="Plant History" />
                        </Link>
                        
                        <button className="menu-button" onClick={() => setShowSideMenu(true)}>
                            <img src={uiMode === "dark-mode" ? menu_icon_white : menu_icon_dark} alt="Side Menu" />
                        </button>
                    </div>
                </>
            )}
            
            {/* Show these elements only when in edit mode */}
            {editMode && (
                <div className="edit-mode-container">
                    <div className="left-section">
                        <div className="back-button" onClick={toggleEdit}>
                            <img src={uiMode === "dark-mode" ? back_icon : back_icon_dark} alt="Go Back" />
                        </div>
                        
                        <div className="edit-mode-title">
                            <h2>Felder bearbeiten</h2>
                        </div>
                    </div>
                    
                    <div className="edit-controls">
                        <div className="edit-rows-cols-container">
                            {/* Row controls */}
                            <div className="edit-control-group">
                                <button 
                                    onClick={removeRow} 
                                    className={nRows <= 2 ? "button-disabled" : ""}
                                    disabled={nRows <= 2}
                                >
                                    <img 
                                        src={uiMode === "dark-mode" ? row_remove_icon : row_remove_icon_black} 
                                        alt="Remove Row" 
                                    />
                                </button>
                                <span>{nRows} Zeilen</span>
                                <button 
                                    onClick={addRow} 
                                    className={nRows >= 5 ? "button-disabled" : ""}
                                    disabled={nRows >= 5}
                                >
                                    <img 
                                        src={uiMode === "dark-mode" ? row_add_icon : row_add_icon_black} 
                                        alt="Add Row" 
                                    />
                                </button>
                            </div>
                            
                            {/* Column controls */}
                            <div className="edit-control-group">
                                <button 
                                    onClick={removeColumn} 
                                    className={nColumns <= 2 ? "button-disabled" : ""}
                                    disabled={nColumns <= 2}
                                >
                                    <img 
                                        src={uiMode === "dark-mode" ? col_remove_icon : col_remove_icon_black} 
                                        alt="Remove Column" 
                                    />
                                </button>
                                <span>{nColumns} Spalten</span>
                                <button 
                                    onClick={addColumn} 
                                    className={nColumns >= 5 ? "button-disabled" : ""}
                                    disabled={nColumns >= 5}
                                >
                                    <img 
                                        src={uiMode === "dark-mode" ? col_add_icon : col_add_icon_black} 
                                        alt="Add Column" 
                                    />
                                </button>
                            </div>
                        </div>
                        
                        <button className="save-edit-button" onClick={toggleEdit}>
                            <img src={accept_icon} alt="Save" />
                            <span>Speichern</span>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TopNavigation; 