import { doc, collection, query, where, getDocs, updateDoc, setDoc } from 'firebase/firestore';
import { firestore } from '../../../context/FirebaseConfig';
import { addMinutes } from 'date-fns';

/**
 * Custom hook to manage document operations in Firestore
 * Handles creating, updating, and closing documents
 */
export function useDocumentManagement(currentUser, activeDocument, linkedDevices, settings, shiftInfo) {
    
    function isButtonActive(button) {
        /* Find out if the button is active */
        if (!button || !activeDocument || !activeDocument.data) {
            return false;
        }
        // Add defensive check for material field for backwards compatibility
        const buttonMaterial = button.material || null;
        const docMaterial = activeDocument.data.material || null;
        
        return (
            activeDocument.data.supplier === button.supplier &&
            activeDocument.data.field === button.field &&
            (docMaterial === buttonMaterial || docMaterial === null || docMaterial === undefined)
        );
    }
    
    async function closeActiveDocument() {
        /* Close active document for current device and all linked devices */
        if (!currentUser?.company?.company || !currentUser?.device_id) {
            return;
        }
        
        await closeActiveDocumentByDeviceId(currentUser.device_id);

        // Also close the active document on linked devices
        if (linkedDevices?.length > 0) {
            await Promise.all(linkedDevices.map((deviceId) => 
                closeActiveDocumentByDeviceId(deviceId)
            ));
        }
    }

    async function closeActiveDocumentByDeviceId(deviceId) {
        /* Close active documents for given deviceId */
        if (!currentUser?.company?.company) {
            return;
        }
        
        // First get the active document ids for the device
        const activeDocumentIds = [];
        if (deviceId === currentUser.device_id) {
            // For the current device, we already know the active document id
            if (activeDocument && activeDocument.id) {
                activeDocumentIds.push(activeDocument.id);
            }
        } else {
            // For other devices, we need to query the active documents
            const q = query(
                collection(firestore, "clients", currentUser.company.company, "devices", deviceId, "bale_feed_timestamps"),
                where("active", "==", true)
            );
            const snapshot = await getDocs(q);
            snapshot.forEach((doc) => {
                activeDocumentIds.push(doc.id);
            });
        }

        // Close the active documents
        await closeActiveDocumentsByIds(deviceId, activeDocumentIds);
    }

    async function closeActiveDocumentsByIds(deviceId, activeDocumentIds) {
        /* Close all active documents (i.e. active buttons) given their IDs */
        if (!currentUser?.company?.company) {
            return;
        }
        
        for (const documentId of activeDocumentIds) {
            const activeDocRef = doc(firestore, "clients", currentUser.company.company, "devices", deviceId, "bale_feed_timestamps", documentId);
            await updateDoc(activeDocRef, {end_timestamp: new Date(), active: false});
        }
    }

    async function openActiveDocument(supplier, field, material, deviceId, closeExistingActiveDocs = true) {
        /*
            Open a new active document for the device with the specified supplier, field and material.
            use closeActiveDocs to close all active documents on the device before opening the new one.
        */
        if (!currentUser?.company?.company || !currentUser?.uid) {
            return;
        }
        
        // Check if there are any active documents on the device and close them
        if (closeExistingActiveDocs) {
            await closeActiveDocumentByDeviceId(deviceId);
        }

        // Open the new active document for the device
        const currentDate = new Date();
        const expirationDate = addMinutes(currentDate, settings?.stamp_max_duration || 60);
        const activeDocRef = doc(
            firestore, 
            "clients", 
            currentUser.company.company, 
            "devices", 
            deviceId, 
            "bale_feed_timestamps", 
            currentDate.getTime().toString()
        );
        
        await setDoc(activeDocRef, {
            supplier: supplier, 
            field: field,
            // only include material if it exists
            ...(material !== undefined ? {material: material || null } : {}),
            user: currentUser.uid,
            start_timestamp: currentDate, 
            expiration_timestamp: expirationDate,
            active: true,
            shift_info: shiftInfo,
            device_id: deviceId,
            client_id: currentUser.company.company,
        });
    }

    async function extendStampTimeout() {
        /** Extend the stamp timeout */
        if (!activeDocument || !currentUser?.company?.company || !currentUser?.device_id) {
            return;
        }
        
        const newExpirationDate = addMinutes(new Date(), settings?.stamp_max_duration || 60);
        const activeDocRef = doc(
            firestore, 
            "clients", 
            currentUser.company.company, 
            "devices", 
            currentUser.device_id, 
            "bale_feed_timestamps", 
            activeDocument.id
        );
        
        await updateDoc(activeDocRef, {
            expiration_timestamp: newExpirationDate
        });

        // Extend stamps for linked devices -> get active documents for same [supplier, field, material] and extend stamp
        if (linkedDevices?.length > 0) {
            for (const deviceId of linkedDevices) {
                const q = query(
                    collection(firestore, "clients", currentUser.company.company, "devices", deviceId, "bale_feed_timestamps"),
                    where("active", "==", true),
                    where("supplier", "==", activeDocument.data.supplier),
                    where("field", "==", activeDocument.data.field), 
                    where("material", "==", activeDocument.data.material)
                );
                const snapshot = await getDocs(q);
                snapshot.forEach((document) => {
                    const activeDocRef = doc(
                        firestore, 
                        "clients", 
                        currentUser.company.company, 
                        "devices", 
                        deviceId, 
                        "bale_feed_timestamps", 
                        document.id
                    );
                    updateDoc(activeDocRef, {
                        expiration_timestamp: newExpirationDate
                    });
                });
            }
        }
    }

    function updateButtonSelection(newButtonSelection, nRows, nColumns, linkedDevices = []) {
        /* Update the button selection in the firestore */
        if (!currentUser?.company?.company || !currentUser?.device_id) {
            return;
        }
        // Clean the button data to avoid undefined values
        const cleanedButtonSelection = newButtonSelection.map(button => ({
            supplier: button.supplier || null,
            field: button.field || null,
            ...(button.material !== undefined ? { material: button.material || null } : {})
        }));
        
        const deviceRef = doc(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id);
        updateDoc(deviceRef, {
            last_plant_feed_selection: {
                button_selection: newButtonSelection,
                n_rows: nRows,
                n_columns: nColumns,
                linked_devices: linkedDevices,
            }
        });
    }

    return {
        isButtonActive,
        closeActiveDocument,
        closeActiveDocumentByDeviceId,
        closeActiveDocumentsByIds,
        openActiveDocument,
        extendStampTimeout,
        updateButtonSelection
    };
} 