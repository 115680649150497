import { doc, collection, query, where, onSnapshot, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../../context/FirebaseConfig';

/**
 * Custom hook to manage Firestore subscriptions
 * Handles subscribing to various collections and documents
 */
export function useFirestoreSubscriptions(
    currentUser,
    setButtonSelection,
    setShiftInfo,
    setNRows,
    setNColumns,
    setLinkedDevices,
    setDeviceName,
    setSettings,
    setActiveDocument,
    setSupplierLabelset,
    setFieldLabelset,
    setMaterialLabelset,
    setConveyorCode,
    updateUiMode // Receive updateUiMode from ThemeContext
) {
    // Function to subscribe to button selection updates
    function subscribeToButtonSelection() {
        /* Subscribe to the button selection in the firestore */
        if (!currentUser?.company?.company || !currentUser?.device_id) {
            return () => {};
        }
        
        const q = doc(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id);
        
        return onSnapshot(q, async (snapshot) => {
            const document = snapshot.data();
            if (document?.last_plant_feed_selection?.button_selection) {
                setButtonSelection(document.last_plant_feed_selection.button_selection);
            }
            if (document?.lastPlantFeedShiftInfo) {
                setShiftInfo(document.lastPlantFeedShiftInfo);
            }
            if (document?.last_plant_feed_selection?.n_rows && document?.last_plant_feed_selection?.n_columns) {
                setNRows(document.last_plant_feed_selection.n_rows);
                setNColumns(document.last_plant_feed_selection.n_columns);
            }
            if (document?.last_plant_feed_selection?.linked_devices) {
                setLinkedDevices(document.last_plant_feed_selection.linked_devices);
            }
            setDeviceName(document?.display_name ?? currentUser.device_id);
            
            // Also set the conveyor code if it exists, otherwise use "BAND" as fallback
            if (document?.conveyor_code) {
                setConveyorCode(document.conveyor_code);
            } else {
                setConveyorCode("BAND");
                
                // Update the Firestore document with the default conveyor_code
                try {
                    await updateDoc(q, { conveyor_code: "BAND" });
                    console.log("Updated device with default conveyor_code: BAND");
                } catch (error) {
                    console.error("Error updating device with default conveyor_code:", error);
                }
            }
        });
    }

    function subscribeToActiveDocuments(closeActiveDocumentsByIds) {
        /* Subscribe to active documents (i.e. clicked buttons) in the firestore */
        if (!currentUser?.company?.company || !currentUser?.device_id) {
            return () => {};
        }
        
        const q = query(
            collection(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id, "bale_feed_timestamps"),
            where("active", "==", true)
        );
        
        return onSnapshot(q, (snapshot) => {
            const activeDocuments = {};
            snapshot.forEach((document) => {
                activeDocuments[document.id] = document.data();
            });
            
            if (Object.keys(activeDocuments).length === 0) {
                // No active documents
                setActiveDocument(false);
            } else if (Object.keys(activeDocuments).length === 1) {
                // Single active document
                setActiveDocument({
                    id: Object.keys(activeDocuments)[0],
                    data: Object.values(activeDocuments)[0],
                });
            } else {
                // Multiple active documents -> Error -> Close all
                console.error("Multiple active documents detected, closing all");
                if (closeActiveDocumentsByIds) {
                    closeActiveDocumentsByIds(currentUser.device_id, Object.keys(activeDocuments));
                    setActiveDocument(false);
                } else {
                    console.error("closeActiveDocumentsByIds function not provided");
                }
            }
        });
    }

    function subscribeToBaleFeedSettings() {
        /* Subscribe to document containing settings such as bale-feed-workers names */
        
        // Check if currentUser and company are available
        if (!currentUser || !currentUser.company || !currentUser.company.company) {
            return () => {};
        }
        
        const q = doc(firestore, "clients", currentUser.company.company, "client_settings", "bale_feed_settings");
        
        return onSnapshot(q, async (snapshot) => {
            if (!snapshot.exists()) {
                // Document doesn't exist, create it with default UI mode
                if (updateUiMode) {
                    await updateUiMode("dark-mode");
                }
                return;
            }
            
            const data = snapshot.data();
            setSettings(data);
            
            // Set UI mode from Firebase settings
            const appMode = data?.stamp_app_mode;
            
            if (appMode === "light-mode" || appMode === "dark-mode") {
                if (updateUiMode) {
                    updateUiMode(appMode);
                }
            } else {
                // Mode doesn't exist or is invalid, set default and update in Firebase
                if (updateUiMode) {
                    await updateUiMode("dark-mode");
                }
            }
        }, (error) => {
            console.error("Error subscribing to bale feed settings:", error);
        });
    }

    function subscribeToSupplierLabelset() {
        /* Load the supplier labelset */
        if (!currentUser?.company?.company) {
            return () => {};
        }
        
        const q = doc(firestore, "clients", currentUser.company.company, "labelsets", "supplier");
        return onSnapshot(q, (snapshot) => {
            if (!snapshot.exists()) {
                setSupplierLabelset({});
                return;
            }
            setSupplierLabelset(snapshot.data());
        });
    }

    function subscribeToFieldLabelset() {
        /* Load the field labelset */
        if (!currentUser?.company?.company) {
            return () => {};
        }
        
        const q = doc(firestore, "clients", currentUser.company.company, "labelsets", "stock_field");
        return onSnapshot(q, (snapshot) => {
            if (!snapshot.exists()) {
                setFieldLabelset({});
                return;
            }
            setFieldLabelset(snapshot.data());
        });
    }

     function subscribeToMaterialLabelset() {
        /* Load the material labelset */
        if (!currentUser?.company?.company) {
            return () => {};
        }
        
        const q = doc(firestore, "clients", currentUser.company.company, "labelsets", "materials");
        return onSnapshot(q, (snapshot) => {
            if (!snapshot.exists()) {
                setMaterialLabelset({});
                return;
            }
            setMaterialLabelset(snapshot.data());
        });
    }

    return {
        subscribeToButtonSelection,
        subscribeToBaleFeedSettings,
        subscribeToSupplierLabelset,
        subscribeToFieldLabelset,
        subscribeToMaterialLabelset,
        subscribeToActiveDocuments
    };
} 