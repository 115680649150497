import { useState, useEffect } from 'react';

/**
 * Custom hook to detect device orientation
 * @param {Object} options - Configuration options
 * @param {number} options.mobileMaxWidth - Maximum width to consider a device as mobile (default: 480px)
 * @param {number} options.tabletMaxWidth - Maximum width to consider a device as tablet (default: 1024px)
 * @param {boolean} options.onlyMobileTablet - Only show for mobile/tablet devices (default: true)
 * @param {boolean} options.useNativeAPI - Try to use the native orientation API if available (default: true)
 * @returns {Object} Orientation information
 * @returns {boolean} Orientation information.isPortrait - Whether the device is in portrait mode
 * @returns {string} Orientation information.deviceType - The type of device ('mobile', 'tablet', 'desktop')
 */
const useOrientation = (options = {}) => {
  const {
    mobileMaxWidth = 480,
    tabletMaxWidth = 1024,
    onlyMobileTablet = true,
    useNativeAPI = true
  } = options;

  const [orientationState, setOrientationState] = useState({
    isPortrait: false,
    deviceType: 'desktop',
    orientationAngle: null
  });

  useEffect(() => {
    // Function to determine device type based on screen width
    const getDeviceType = () => {
      const width = window.innerWidth;
      if (width <= mobileMaxWidth) return 'mobile';
      if (width <= tabletMaxWidth) return 'tablet';
      return 'desktop';
    };

    // Function to check if the device is in portrait mode
    const checkOrientation = () => {
      let isPortrait = false;
      let orientationAngle = null;

      // Try to use the native orientation API if available and enabled
      if (useNativeAPI && window.orientation !== undefined) {
        orientationAngle = window.orientation;
        isPortrait = orientationAngle === 0 || orientationAngle === 180;
      } else {
        // Fallback to comparing window dimensions
        isPortrait = window.innerWidth < window.innerHeight;
      }

      const deviceType = getDeviceType();
      
      // If onlyMobileTablet is true, desktop devices are always considered in landscape mode
      if (onlyMobileTablet && deviceType === 'desktop') {
        isPortrait = false;
      }

      setOrientationState({
        isPortrait,
        deviceType,
        orientationAngle
      });
    };

    // Check orientation on mount
    checkOrientation();

    // Add event listeners
    window.addEventListener('resize', checkOrientation);
    
    if (useNativeAPI && window.orientation !== undefined) {
      window.addEventListener('orientationchange', checkOrientation);
    }

    // Clean up event listeners on unmount
    return () => {
      window.removeEventListener('resize', checkOrientation);
      
      if (useNativeAPI && window.orientation !== undefined) {
        window.removeEventListener('orientationchange', checkOrientation);
      }
    };
  }, [mobileMaxWidth, tabletMaxWidth, onlyMobileTablet, useNativeAPI]);

  return orientationState;
};

export default useOrientation; 