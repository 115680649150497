import React from 'react';
import user_icon from '../../../assets/icons/user.png';
import user_icon_dark from '../../../assets/icons/user_dark.png';
import logout_icon from '../../../assets/icons/logout.png';
import logout_icon_dark from '../../../assets/icons/logout_dark.png';
import moon_light from '../../../assets/icons/moon_light.png';
import moon_dark from '../../../assets/icons/moon_dark.png';
import sun_light from '../../../assets/icons/sun_light.png';
import sun_dark from '../../../assets/icons/sun_dark.png';

/**
 * SideMenu component for the FeedTimestamping page
 * Displays options for user switching, theme toggling, and logout
 * Also shows the currently active user at the bottom
 */
const SideMenu = ({ 
    showSideMenu, 
    setShowSideMenu, 
    uiMode, 
    updateUiMode, 
    setShowShiftSettingsModal, 
    setConfirmLogout,
    currentUser,
    shiftInfo,
    settings
}) => {
    // Get the active user's name from settings based on shiftInfo.user
    const getActiveUserName = () => {
        if (shiftInfo?.user && settings?.users && settings.users[shiftInfo.user]) {
            const user = settings.users[shiftInfo.user];
            return `${user.firstname} ${user.lastname}`;
        }
        return currentUser?.displayName || currentUser?.email || "User";
    };

    return (
        <div className={`side-menu-overlay ${showSideMenu ? 'active' : ''}`}>
            <div className={`side-menu ${showSideMenu ? 'open' : ''}`}>
                <div className="side-menu-header">
                    <h2>Menu</h2>
                    <button className="close-button" onClick={() => setShowSideMenu(false)}>×</button>
                </div>
                
                <div className="side-menu-options">
                    <button className="menu-option" onClick={() => {
                        setShowSideMenu(false);
                        setShowShiftSettingsModal(true);
                    }}> 
                        <img 
                            src={uiMode === "dark-mode" 
                                ? user_icon  // In dark mode, use light sun icon for contrast
                                : user_icon_dark  // In light mode, use dark moon icon for contrast
                            } 
                            alt="Benutzer wechseln" 
                        />
                        <span>Benutzer wechseln</span>
                    </button>
                    
                    <button className="menu-option" onClick={() => {
                        const newMode = uiMode === "dark-mode" ? "light-mode" : "dark-mode";
                        updateUiMode(newMode);
                        setShowSideMenu(false);
                    }}>
                        <img 
                            src={uiMode === "dark-mode" 
                                ? sun_light  // In dark mode, use light sun icon for contrast
                                : moon_dark  // In light mode, use dark moon icon for contrast
                            } 
                            alt="Switch Mode" 
                        />
                        <span>{uiMode === "dark-mode" ? "Light Mode" : "Dark Mode"}</span>
                    </button>
                    
                    <button className="menu-option" onClick={() => {
                        setShowSideMenu(false);
                        setConfirmLogout(true);
                    }}> 
                        <img 
                            src={uiMode === "dark-mode" 
                                ? logout_icon  // In dark mode, use light sun icon for contrast
                                : logout_icon_dark  // In light mode, use dark moon icon for contrast
                            } 
                            alt="Abmelden" 
                        />
                        <span>Abmelden</span>
                    </button>
                </div>
                
                {/* Active user display at the bottom */}
                <div className="active-user-display">
                    <div className="active-user-label">Aktiver Benutzer</div>
                    <div className="active-user-name">{getActiveUserName()}</div>
                </div>
            </div>
        </div>
    );
};

export default SideMenu; 