import React, { useEffect, useState } from 'react';
import './RotationPrompt.scss';

/**
 * Component that displays an animation prompting users to rotate their device to landscape mode
 * This is shown when the app is in portrait mode on tablets/mobile devices
 * 
 * @param {Object} props - Component props
 * @param {string} props.deviceType - The type of device ('phone' or 'tablet')
 */
const RotationPrompt = ({ deviceType = 'tablet' }) => {
  const [orientation, setOrientation] = useState(null);

  useEffect(() => {
    // Try to use the DeviceOrientation API if available
    const handleOrientationChange = () => {
      if (window.orientation !== undefined) {
        // 0 or 180 means portrait, 90 or -90 means landscape
        setOrientation(window.orientation === 0 || window.orientation === 180 ? 'portrait' : 'landscape');
      }
    };

    // Initial detection
    handleOrientationChange();
    
    // Add event listeners
    if (window.orientation !== undefined) {
      window.addEventListener('orientationchange', handleOrientationChange);
    }

    // Clean up
    return () => {
      if (window.orientation !== undefined) {
        window.removeEventListener('orientationchange', handleOrientationChange);
      }
    };
  }, []);

  return (
    <div className="rotation-prompt">
      <div className="rotation-container">
        <div className={`device ${deviceType}`}>
          <div className="screen"></div>
          <div className="home-button"></div>
        </div>
        <div className="rotation-arrow">
          <div className="arrow-body"></div>
          <div className="arrow-head"></div>
        </div>
        <div className="message">
          <h2>Bitte drehen Sie Ihr Gerät</h2>
          <p>Diese Anwendung funktioniert am besten im Querformat</p>
          {orientation && (
            <p className="orientation-status">
              Aktuelle Ausrichtung: <span className="highlight">{orientation}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RotationPrompt; 