import React, { useState, useEffect, useContext } from 'react';
import { formatDuration } from '../../../utils/timeUtils';
import { AuthContext } from '../../../context/AuthContext';
import { useMaterialSuggestions } from '../hooks/useMaterialSuggestions';

// Import icons for the dropdown fields
import supplierIconDarkmode from '../../../assets/icons/supplier_icon_darkmode.png';
import supplierIconLightmode from '../../../assets/icons/supplier_icon_lightmode.png';
import fieldIconDarkmode from '../../../assets/icons/field_icon_darkmode.png';
import fieldIconLightmode from '../../../assets/icons/field_icon_lightmode.png';
import materialIconDarkmode from '../../../assets/icons/material_icon_darkmode.png';
import materialIconLightmode from '../../../assets/icons/material_icon_lightmode.png';

// Function to generate consistent colors from material IDs
const getMaterialColor = (materialId) => {
    if (!materialId) return '#cccccc'; // Default gray for no material
    
    // Pre-defined color palette - you can customize these colors
    // const colorPalette = [
    //     '#FF5733', '#33FF57', '#3357FF', '#FF33A6',
    //     '#33FFF5', '#F5FF33', '#FF5733', '#C233FF',
    //     '#FF8C33', '#33FFCE', '#8C33FF', '#FFCE33'
    // ];

    // secondary palette
    const colorPalette = [
    // Primary colors
    '#4285F4', // Google Blue
    '#EA4335', // Google Red
    '#FBBC05', // Google Yellow
    '#34A853', // Google Green
    
    // Extended palette
    '#5E97F6', // Light Blue
    '#A142F4', // Purple
    '#24C1E0', // Cyan
    '#6D4C41', // Darker Brown
    
    // Muted tones (Airtable-like)
    '#A1887F', // Lighter Brown
    '#FF8A65', // Coral
    '#9CCC65', // Lime
    '#7986CB', // Indigo
    
    // Soft pastels
    '#FFCA28', // Amber
    '#78909C', // Blue Grey
    '#FF7043', // Deep Orange
    '#26A69A'  // Green
];
    
    // Generate a numeric hash from the material ID string
    const hashCode = materialId.split('').reduce(
        (acc, char) => acc + char.charCodeAt(0), 0
    );
    
    // Use the hash to select a color from the palette
    return colorPalette[hashCode % colorPalette.length];
};

/**
 * ButtonGrid component for the FeedTimestamping page
 * Displays a grid of supplier/field buttons that can be clicked to activate
 * or edited in edit mode
 */
const ButtonGrid = ({ 
    buttonSelection, 
    nRows, 
    nColumns, 
    editMode, 
    isButtonActive, 
    selectionEdited, 
    toggleSelection, 
    supplierLabelset = {}, 
    fieldLabelset = {},
    materialLabelset = {},
    activeDocument = null,
    theme 
}) => {
    const { currentUser } = useContext(AuthContext);
    const { 
        suggestedMaterials, 
        isLoading, 
        fetchSuggestedMaterial, 
        clearSuggestedMaterial 
    } = useMaterialSuggestions();
    
    // Ensure supplierLabelset, fieldLabelset and materialLabelset have a labels property
    const supplierLabels = supplierLabelset?.labels || {};
    const fieldLabels = fieldLabelset?.labels || {};
    const materialLabels = materialLabelset?.labels || {};
    
    // State to track the current time for updating the timer
    const [currentTime, setCurrentTime] = useState(new Date());
    
    // Update the timer every second
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        
        return () => clearInterval(timer);
    }, []);
    
    // Add state to track dismissed notifications
    const [dismissedNotifications, setDismissedNotifications] = useState({});
    
    // Function to dismiss a notification
    const dismissNotification = (index) => {
        setDismissedNotifications(prev => ({
            ...prev,
            [index]: true
        }));
    };
    
    // Handle material selection and apply suggestion
    const handleMaterialChange = (index, value) => {
        // Clear any suggestion when the user manually selects a material
        clearSuggestedMaterial(index);
        selectionEdited(index, "material", value);
    };
    
    // Handle supplier or field change to fetch material suggestions
    const handleFieldOrSupplierChange = (index, key, value) => {
        // Always clear material when changing field or supplier
        // Clear material first to avoid state conflicts
        selectionEdited(index, "material", "");
        clearSuggestedMaterial(index);
        
        // Then update the field/supplier
        selectionEdited(index, key, value);
        
        // Check if we can fetch suggestions with the updated values
        const updatedField = key === "field" ? value : buttonSelection[index].field;
        const updatedSupplier = key === "supplier" ? value : buttonSelection[index].supplier;
        
        // Only fetch suggestion if both supplier and field will be set
        if (updatedSupplier && updatedField) {
            const supplierId = supplierLabels[updatedSupplier]?.id || updatedSupplier;
            const fieldId = updatedField;
            const companyId = currentUser?.company?.company;
            
            // Fetch material suggestion with a small delay to ensure state updates complete
            setTimeout(() => {
                fetchSuggestedMaterial(supplierId, fieldId, companyId, index);
            }, 100); // Use a slightly longer delay to ensure state updates
        }
    };

    // Apply suggestion to the material dropdown
    useEffect(() => {
    // For each button with a suggestion
    Object.entries(suggestedMaterials).forEach(([index, suggestion]) => {
        if (suggestion.found && suggestion.material) {
        const buttonIndex = parseInt(index);
        const currentButton = buttonSelection[buttonIndex];
        
        // Debug material matching
        console.log("Available material keys:", Object.keys(materialLabels));
        console.log("Attempting to select material:", suggestion.material);
        console.log("Material exists in dropdown:", materialLabels.hasOwnProperty(suggestion.material));
        
        // Only auto-select if the material exists in options, and the field and supplier are set
        if (currentButton.supplier && currentButton.field) {
        
            if (currentButton.material !== suggestion.material) {
                if (materialLabels.hasOwnProperty(suggestion.material)) {
                    selectionEdited(buttonIndex, "material", suggestion.material);
                    console.log("Successfully set material:", suggestion.material);
                } else {
                    console.warn("Material found in delivery but does not exist in dropdown options:", suggestion.material);
                }
            }
        }
        }
    });
    }, [suggestedMaterials, buttonSelection, materialLabels, selectionEdited]);
    
    // Helper function to get the right icon based on theme
    const getIcon = (field) => {
        // Default to dark theme if none is provided
        const currentTheme = theme || document.body.classList.contains('light-mode') ? 'light' : 'dark';
        
        if (currentTheme === 'light') {
            switch(field) {
                case 'supplier': return supplierIconLightmode;
                case 'field': return fieldIconLightmode;
                case 'material': return materialIconLightmode;
                default: return null;
            }
        } else {
            switch(field) {
                case 'supplier': return supplierIconDarkmode;
                case 'field': return fieldIconDarkmode;
                case 'material': return materialIconDarkmode;
                default: return null;
            }
        }
    };
    
    return (
        <div
            className="button-container"
            style={{
                display: 'grid',
                gridTemplateRows: `repeat(${nRows}, 1fr)`,
                gridTemplateColumns: `repeat(${nColumns}, 1fr)`
            }}
        >
            {buttonSelection.map((selection, i) => {
                if (editMode) {
                    const isCompact = nRows > 2 || nColumns > 4;
                    const suggestion = suggestedMaterials[i];
                    const isLoadingCurrentButton = isLoading[i];
                    
                    return (
                        <div 
                            key={i} 
                            className={`supplier-button ${isButtonActive(selection) ? "active" : ""} ${isCompact ? "compact" : ""}`}
                        >
                            <div className={`input-field-container ${isCompact ? "compact" : ""}`}>
                                {/* Supplier section */}
                                {!isCompact ? (
                                    // Non-compact mode: Icon next to label
                                    <div className="field-header supplier-header">
                                        <div className="field-icon">
                                            <img src={getIcon('supplier')} alt="Supplier icon" />
                                        </div>
                                        <p>Lieferant</p>
                                    </div>
                                ) : null}
                                
                                <div className="select-with-icon">
                                    {isCompact && (
                                        <div className="field-icon">
                                            <img src={getIcon('supplier')} alt="Supplier icon" />
                                        </div>
                                    )}
                                    <select 
                                        className="settings-select"
                                        value={selection.supplier || ""}
                                        onChange={(e) => handleFieldOrSupplierChange(i, "supplier", e.target.value)}
                                        required
                                    >
                                        <option key={-1} value="" className="supplier-placeholder">Lieferant auswählen</option>
                                        {Object.entries(supplierLabels)
                                            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB, undefined, {numeric: true}))
                                            .map(([key, value]) => (
                                                <option key={key} value={key}>{value?.full_name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                
                                {/* Field section */}
                                {!isCompact ? (
                                    // Non-compact mode: Icon next to label
                                    <div className="field-header field-section-header">
                                        <div className="field-icon">
                                            <img src={getIcon('field')} alt="Field icon" />
                                        </div>
                                        <p>Feld</p>
                                    </div>
                                ) : null}
                                
                                <div className="select-with-icon">
                                    {isCompact && (
                                        <div className="field-icon">
                                            <img src={getIcon('field')} alt="Field icon" />
                                        </div>
                                    )}
                                    <select 
                                        className="settings-select"
                                        value={selection.field || ""}
                                        onChange={(e) => handleFieldOrSupplierChange(i, "field", e.target.value)}
                                        required
                                    >
                                        <option key={-1} value="" className="field-placeholder">Feld auswählen</option>
                                        {Object.entries(fieldLabels)
                                            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB, undefined, {numeric: true}))
                                            .map(([key, value]) => (
                                                <option key={key} value={key}>{value?.full_name}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                {/* Material section */}
                                {!isCompact ? (
                                    // Non-compact mode: Icon next to label
                                    <div className="field-header material-header">
                                        <div className="field-icon">
                                            <img src={getIcon('material')} alt="Material icon" />
                                        </div>
                                        <p>Material</p>
                                    </div>
                                ) : null}
                                
                                <div className="select-with-icon material-select-container">
                                    {isCompact && (
                                        <div className="field-icon">
                                            <img src={getIcon('material')} alt="Material icon" />
                                        </div>
                                    )}
                                    <select 
                                        className={`settings-select ${
                                            suggestion?.found ? 'has-suggestion' : 
                                            suggestion ? 'no-suggestion' : 
                                            isLoadingCurrentButton ? 'loading-suggestion' : ''
                                        }`}
                                        value={selection.material || ""}
                                        onChange={(e) => handleMaterialChange(i, e.target.value)}
                                        required
                                    >                                    
                                        <option key={-1} value="" className="material-placeholder">Material auswählen</option>
                                        {Object.entries(materialLabels)
                                            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB, undefined, {numeric: true}))
                                            .map(([key, value]) => (
                                                <option key={key} value={key}>{value?.full_name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                
                                {/* Show suggestion status message with dismiss button - will be hidden in compact mode via CSS */}
                                {suggestion && !dismissedNotifications[i] && (
                                    <div className={`material-suggestion ${suggestion.found ? 'found' : 'not-found'}`}>
                                        <span className="suggestion-message">
                                            {suggestion.found 
                                                ? "Material automatisch gesetzt" 
                                                : suggestion.message
                                            }
                                        </span>
                                        <button 
                                            type="button"
                                            className="dismiss-button"
                                            onClick={() => dismissNotification(i)}
                                            title="Nachricht schließen"
                                        >
                                            OK
                                        </button>
                                    </div>
                                )}
                                {isLoadingCurrentButton && !dismissedNotifications[i] && (
                                    <div className="material-suggestion loading">
                                        <span className="suggestion-message">Lade Vorschläge...</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                } else {
                    const isActive = isButtonActive(selection);
                    const isCompact = nRows > 2 || nColumns > 3;

                    return (
                        <button 
                            key={i} 
                            className={`supplier-button ${isActive ? "active" : ""} ${isCompact ? "compact" : ""} ${selection.supplier || selection.field ? "supplier-button-clickable" : ""}`}
                            onClick={() => toggleSelection(buttonSelection[i])}
                            disabled={!selection.supplier && !selection.field}
                        >
                            {selection.material ? (
                                <>
                                    {/* In compact mode: show bullet for inactive, tag for active */}
                                    {isCompact ? (
                                        isActive ? (
                                            <div className="material-tag" style={{ backgroundColor: getMaterialColor(selection.material) }}>
                                                {materialLabels[selection.material]?.full_name || selection.material}
                                            </div>
                                        ) : (
                                            <div 
                                                className="material-bullet" 
                                                style={{ backgroundColor: getMaterialColor(selection.material) }}
                                            />
                                        )
                                    ) : (
                                        // Normal mode: show only tag, no bullet
                                        <div className="material-tag" style={{ backgroundColor: getMaterialColor(selection.material) }}>
                                            {materialLabels[selection.material]?.full_name || selection.material}
                                        </div>
                                    )}
                                </>
                            ) : (
                                // No material case - Apply the same compact/active logic
                                <>
                                    {isCompact ? (
                                        isActive ? (
                                            <div className="material-tag" style={{ backgroundColor: '#cccccc' }}>
                                                Material undefiniert
                                            </div>
                                        ) : (
                                            <div className="material-bullet" style={{ backgroundColor: '#cccccc' }} />
                                        )
                                    ) : (
                                        // Normal mode: show only tag, no bullet
                                        <div className="material-tag" style={{ backgroundColor: '#cccccc' }}>
                                            Material undefiniert
                                        </div>
                                    )}
                                </>
                            )}
                            
                            {/* Rest of your button content remains unchanged */}
                            <div className="supplier">{supplierLabelset?.labels?.[selection.supplier]?.id ?? selection.supplier}</div>
                            <div className="supplier-name">{supplierLabelset?.labels?.[selection.supplier]?.display_name ?? selection.supplier}</div>
                            <div className="field">
                                {(fieldLabels[selection.field]?.full_name || selection.field) ? (
                                    <div className="field">
                                        <span className="field-label">
                                            Feld {fieldLabels[selection.field]?.full_name || selection.field}
                                        </span>
                                    </div>
                                ) : (
                                    <div className="no-field">Keine Felder definiert</div>
                                )}
                            </div>
                            
                            {isActive && activeDocument?.data?.start_timestamp && (
                                <div className="timer-indicator">
                                    {formatDuration(activeDocument.data.start_timestamp.toDate())}
                                </div>
                            )}
                        </button>
                    );
                }
            })}
        </div>
    );
};

export default ButtonGrid;