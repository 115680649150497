import { useState, useCallback } from 'react';
import { query, collection, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { firestore } from '../../../context/FirebaseConfig';

/**
 * Hook to fetch suggested materials based on supplier and field selection
 * This hook provides material suggestions for the ButtonGrid component
 * based on previous deliveries in Firestore
 */
export function useMaterialSuggestions() {
  const [suggestedMaterials, setSuggestedMaterials] = useState({});
  const [isLoading, setIsLoading] = useState({});
  
  /**
   * Fetches a suggested material for a specific supplier/field combination
   * @param {string} supplierId - The supplier ID in Firestore
   * @param {string} fieldId - The field ID in Firestore
   * @param {string} companyId - The company ID for the client path
   * @param {number|string} index - The index or key to store the result under
   * @returns {Promise<void>}
   */
  const fetchSuggestedMaterial = useCallback(async (supplierId, fieldId, companyId, index) => {
    if (!supplierId || !fieldId || !companyId || index === undefined) {
      console.log(`Missing parameters for material suggestion: supplierId=${supplierId}, fieldId=${fieldId}, companyId=${companyId}, index=${index}`);
      setSuggestedMaterials(prev => {
        if (prev[index]?.message !== "Erforderliche Parameter fehlen") {
          return {
            ...prev,
            [index]: { found: false, message: "Erforderliche Parameter fehlen" }
          };
        }
        return prev;
      });
      setIsLoading(prev => ({ ...prev, [index]: false }));
      return;
    }

    if (isLoading[index]) return;
    
    setIsLoading(prev => ({ ...prev, [index]: true }));
    console.log(`Fetching material suggestion for supplierId=${supplierId}, fieldId=${fieldId}, companyId=${companyId}, index=${index}`);
    
    try {
      const clientPath = `clients/${companyId}`;
      const deliveriesRef = collection(firestore, `${clientPath}/deliveries`);
      
      const materialQuery = query(
        deliveriesRef,
        where('supplier', '==', supplierId),
        where('storage_field', '==', fieldId),
        orderBy('date', 'desc'),
        limit(1)
      );
      
      console.log(`Executing query: supplier=${supplierId}, storage_field=${fieldId}`);
      const querySnapshot = await getDocs(materialQuery);
      
      if (querySnapshot.empty) {
        console.log(`No matching documents found for button ${index}`);
        setSuggestedMaterials(prev => {
          if (prev[index]?.message !== "Keine Übereinstimmung gefunden, bitte Material wählen") {
            return {
              ...prev,
              [index]: { 
                found: false, 
                message: "Keine Übereinstimmung gefunden, bitte Material wählen" 
              }
            };
          }
          return prev;
        });
      } else {
        const doc = querySnapshot.docs[0];
        const data = doc.data();
        
        // Access the correct path to material
        const material = data.base_labels?.materials; // Get material ID from delivery
        
        console.log(`Found material in delivery for button ${index}: ${material}`);
        
        if (!material) {
          console.warn(`Material not found in the expected structure for document ${doc.id}`);
          console.log('Document data:', data); // Log full document data for debugging
          setSuggestedMaterials(prev => ({
            ...prev,
            [index]: { 
              found: false, 
              message: "Material in Lieferung gefunden, aber Format nicht unterstützt" 
            }
          }));
        } else {
          // No need to fetch additional material info here
          // The ButtonGrid component already has the materialLabels from props
          setSuggestedMaterials(prev => {
            if (prev[index]?.material !== material) {
              return {
                ...prev,
                [index]: { 
                  found: true, 
                  material: material, // This is the key for the material dropdown
                  date: data.date
                }
              };
            }
            return prev;
          });
        }
      }
    } catch (error) {
      console.error(`Error fetching material suggestion for button ${index}:`, error);
      setSuggestedMaterials(prev => {
        if (prev[index]?.message !== "Fehler beim Laden der Vorschläge") {
          return {
            ...prev,
            [index]: { found: false, message: "Fehler beim Laden der Vorschläge" }
          };
        }
        return prev;
      });
    } finally {
      setIsLoading(prev => ({ ...prev, [index]: false }));
    }
  }, [isLoading]);

  /**
   * Clears a material suggestion for a specific index
   * @param {number|string} index - The index to clear
   */
  const clearSuggestedMaterial = useCallback((index) => {
    setSuggestedMaterials(prev => {
      if (prev[index]) {
        const newSuggestions = { ...prev };
        delete newSuggestions[index];
        return newSuggestions;
      }
      return prev;
    });
    
    setIsLoading(prev => {
      if (prev[index]) {
        const newLoading = { ...prev };
        delete newLoading[index];
        return newLoading;
      }
      return prev;
    });
  }, []);

  /**
   * Clears all material suggestions
   */
  const clearAllSuggestedMaterials = useCallback(() => {
    setSuggestedMaterials({});
    setIsLoading({});
  }, []);

  return {
    suggestedMaterials,
    isLoading,
    fetchSuggestedMaterial,
    clearSuggestedMaterial,
    clearAllSuggestedMaterials
  };
}