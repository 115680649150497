import React, { createContext, useState, useEffect, useContext } from 'react';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { firestore } from './FirebaseConfig';
import { AuthContext } from './AuthContext';

export const ThemeContext = createContext();

/**
 * ThemeProvider component that manages the application's theme
 * Syncs theme with Firebase and provides theme-related functions to all components
 */
export const ThemeProvider = ({ children }) => {
  const [uiMode, setUiMode] = useState('dark-mode'); // Default to dark-mode
  const { currentUser } = useContext(AuthContext);
  
  // Load theme from Firebase on initial render
  useEffect(() => {
    const loadThemeFromFirebase = async () => {
      if (!currentUser?.company?.company) return;
      
      try {
        const settingsRef = doc(firestore, "clients", currentUser.company.company, "client_settings", "bale_feed_settings");
        const docSnap = await getDoc(settingsRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          const appMode = data?.stamp_app_mode;
          
          if (appMode === "light-mode" || appMode === "dark-mode") {
            setUiMode(appMode);
          }
        }
      } catch (error) {
        console.error("Error loading theme from Firebase:", error);
      }
    };
    
    loadThemeFromFirebase();
  }, [currentUser]);
  
  // Apply theme to body element
  useEffect(() => {
    document.body.className = uiMode;
  }, [uiMode]);
  
  // Function to update UI mode in Firebase
  const updateUiMode = async (newMode) => {
    if (newMode !== "light-mode" && newMode !== "dark-mode") {
      newMode = "dark-mode"; // Default to dark-mode if invalid value
    }
    
    // Update local state immediately for responsive UI
    setUiMode(newMode);
    
    // Check if currentUser and company are available
    if (!currentUser || !currentUser.company || !currentUser.company.company) {
      return;
    }
    
    const settingsRef = doc(firestore, "clients", currentUser.company.company, "client_settings", "bale_feed_settings");
    
    try {
      // First check if the document exists
      const docSnap = await getDoc(settingsRef);
      
      if (docSnap.exists()) {
        // Document exists, update it
        await updateDoc(settingsRef, {
          stamp_app_mode: newMode
        });
      } else {
        // Document doesn't exist, create it
        await setDoc(settingsRef, {
          stamp_app_mode: newMode
        });
      }
    } catch (error) {
      console.error("Error updating UI mode:", error);
    }
  };
  
  return (
    <ThemeContext.Provider value={{ uiMode, updateUiMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Custom hook for easier theme access
export const useTheme = () => useContext(ThemeContext); 